import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-event/Editor";
import SidebarActions from "../components/add-new-event/SidebarActions";
import SidebarSpeakers from "../components/add-new-event/SidebarSpeakers";
import {
  useCreateBlogMutation,
  useCreateEventMutation,
  useGetSingleBlogLazyQuery,
  useGetSingleEventLazyQuery,
  useUpdateBlogMutation,
  useUpdateEventMutation
} from "../graphql/generated/ncikenya-schema";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import * as smallToast from 'react-hot-toast'

const AddNewEvent = ({props}) => {

  const params = useParams();
  const [event, setEvent] = useState();

  const [createEvent] = useCreateEventMutation({
    onCompleted: (results) => {
      setEvent((current) => {
        return {
          ...current,
          id: results.createEvent.id
        }
      })
    }
  })

  const [getSingleEvent] = useGetSingleEventLazyQuery({
    variables: {
      id: params?.id
    },
    onCompleted: (results) => {
      setEvent(results.event)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateEvent] = useUpdateEventMutation({
    onCompleted: (results) => {
      getSingleEvent()
    }
  });

  const publishEvent = (isDraft = true) => {

    if (event?.id) {
      smallToast.toast.promise(updateEvent(
        {
          variables: {
            id: parseInt(event?.id),
            title: event?.title,
            content: event?.content,
            venue: event?.venue,
            venueLink: event?.venueLink,
            from: event?.from,
            to: event?.to,
            speakers:event?.speakers,
            program:event?.program,
          }
        }
      ),
        {
          success:"Event updated successfully",
          error:"Couldn't update event",
          loading:"Updating your event"
        }
        )

    } else {
      smallToast.toast.promise(
        createEvent(
          {
            variables: {
              title: event?.title,
              content: event?.content,
              venue: event?.venue,
              venueLink: event?.venueLink,
              from: event?.from,
              to: event?.to,
              speakers:event?.speakers,
              program:event?.program,
              coverImage: event?.coverImage.file,
              otherImages: event?.otherImages?.map(i => i.file) ?? [],
              enabled: !isDraft
            }
          }
        ),
        {
          success:"Event created successfully",
          error:"Couldn't create event",
          loading:"creating your event"
        }
      )

    }
  }

  useEffect(() => {
    if (params?.id) {
      getSingleEvent()
    }
  }, [])


  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={event?.id ? "Update Event" : "Add Event"}
                   subtitle="Event Posts"
                   className="text-sm-left"/>
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="8" md="12">
          <Editor event={event} setEvent={setEvent}/>
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="4" md="12">
          <SidebarSpeakers event={event} setEvent={setEvent}/>
          <SidebarActions selectedEvent={event}
                          publishEvent={publishEvent}/>
        </Col>
      </Row>
    </Container>
  )
};

export default AddNewEvent;
