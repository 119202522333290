import React from "react";

export const UserRoles = {
  USER: "USER",
  ADMIN: "ADMIN",
  CONTENT_CREATOR: "CONTENT_CREATOR",
  PUBLIC: "PUBLIC",
}

Number.prototype.formatCurrency = function (n = 0, x = 3, s = ',', c = '.') {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = this.toFixed(Math.max(0, ~~n));
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
String.prototype.toCamelCase = function (removeSpaces = false) {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
String.prototype.ellipsizeMiddle = function (n = 3) {
  return this.substr(0, n) + (this.length > n ? '...' : '') + (this.length > n * 2 ? this.substr(this.length - n, n) : '');
};
String.prototype.isValidEmail = function (n = 3) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this);
};
String.prototype.isValidPassword = function (n = 3) {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this);
};
Array.prototype.count = function (filterMethod) {
  return this.reduce((count, item) => filterMethod(item) ? count + 1 : count, 0);
}
export const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
})
export const generateColor = function () {
  return '#' + Math.random().toString(16).substr(-6);
}
