import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Events from "./views/Events";
import CarouselList from "./views/CarouselList";
import AddNewEvent from "./views/AddNewEvent";
import AddCarousel from "./views/AddCarousel";
import Login from "./views/Login";
import AddNewTender from "./views/tenders/AddNewTender";
import Tenders from "./views/tenders/Tenders";
import Regulations from "./views/regulations/Regulations";
import JobPosts from "./views/job-posts/JobPosts";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: BlogOverview
  },
  // {
  //   path: "/user-profile-lite",
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  {
    path: "/carousel-sliders",
    layout: DefaultLayout,
    component: CarouselList
  },
  {
    path: "/add-carousel",
    layout: DefaultLayout,
    component: AddCarousel
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/tenders",
    layout: DefaultLayout,
    component: Tenders
  },
  {
    path: "/regulations",
    layout: DefaultLayout,
    component: Regulations
  },
  {
    path: "/jobs",
    layout: DefaultLayout,
    component: JobPosts
  },
  // {
  //   path: "/add-new-tender",
  //   layout: DefaultLayout,
  //   component: AddNewTender
  // },
  {
    path: "/update-post/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/update-carousel/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddCarousel
  },
  {
    path: "/events",
    layout: DefaultLayout,
    component: Events
  },
  {
    path: "/add-new-event",
    layout: DefaultLayout,
    component: AddNewEvent
  },
  {
    path: "/update-event/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddNewEvent
  },
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // }
];
