import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import Editor from "../../components/add-new-job/Editor";
import SidebarActions from "../../components/add-new-job/SidebarActions";
import {
  useCreateBlogMutation,
  useCreateJobPostMutation,
  useGetSingleBlogLazyQuery,
  useGetSingleJobPostLazyQuery,
  useGetSingleJobPostQuery,
  useUpdateBlogMutation, useUpdateJobPostMutation
} from "../../graphql/generated/ncikenya-schema";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import * as smallToast from "react-hot-toast";
import {
  useCreateTenderMutation,
  useGetSingleTenderLazyQuery, useUpdateTenderMutation
} from "../../graphql/generated/ncikenya-schema";

const AddNewJobPosts = ({id, ...props}) => {

  const params = useParams();
  const [jobPost, setJobPost] = useState();

  const [createJobPost] = useCreateJobPostMutation({
    onCompleted: (results) => {
      setJobPost((current) => {
        return {
          ...current,
          id: results.createJobPost.id
        }
      })
    }
  })

  const [getSingleJobPost] = useGetSingleJobPostLazyQuery({
    variables: {
      id: params?.id??id
    },
    onCompleted: (results) => {
      setJobPost(results.jobPost)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateTender] = useUpdateJobPostMutation({
    onCompleted: (results) => {
      getSingleJobPost()
    }
  });

  const publishJobPost = (isDraft = true) => {
    if (jobPost?.id) {
      smallToast.toast.promise(
        updateTender(
          {
            variables: {
              id: parseInt(jobPost?.id),
              title: jobPost?.title,
              file: jobPost?.file,
              position: jobPost?.position,
              location: jobPost?.location,
            }
          }
        ),
        {
          success:"Job Post updated successfully",
          error:"Couldn't update tender",
          loading:"Updating your tender"
        }
      )

    } else
      smallToast.toast.promise(
        createJobPost(
          {
            variables: {
              title: jobPost?.title,
              position: jobPost?.position,
              location: jobPost?.location,
              files: jobPost?.files.map(tenderFile=>tenderFile.file),
              otherMedias: jobPost?.otherImages?.map(i => i.file) ?? [],
              enabled: !isDraft
            }
          }
        ),
        {
          success:"Job Post created successfully",
          error:"Couldn't create tender",
          loading:"Creating your tender"
        }
      )

  }

  useEffect(() => {
    if (params?.id || id != null || id != undefined) {
      getSingleJobPost()
    }
  }, [])


  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={jobPost?.id ? "Update Job Post" : "Add Job Post"}
                   subtitle="Job Post"
                   className="text-sm-left"/>
      </Row>

      <Row>
        {/* Editor */}
        <Col md="12">
          <Editor jobPost={jobPost} setJobPost={setJobPost} isFile={true} noContent/>
        </Col>

        {/* Sidebar Widgets */}
        <Col md="12">
          <SidebarActions selectedJobPost={jobPost}
                          publishJobPost={publishJobPost}/>
          {/*<SidebarCategories/>*/}
        </Col>
      </Row>
    </Container>
  )
};

export default AddNewJobPosts;
