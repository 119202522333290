import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import {ApolloProvider} from "@apollo/client";
import {useApolloClient} from "./apollo/client";
import Login from "./views/Login";
import {PrivateRoute} from "./routes/PrivateRoute";
import {UserRoles} from "./config/utils";

export default () => (
  <ApolloProvider client={useApolloClient()}>
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
        {routes.map((route, index) => {
          return (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              allowedRoles={[UserRoles.ADMIN, UserRoles.CONTENT_CREATOR]}
              component={withTracker(props => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}
        <Route
          key={'login'}
          path={"/login"}
          exact={true}
          component={Login}
        />
    </Router>
  </ApolloProvider>
);
