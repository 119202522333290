import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import Editor from "../../components/add-new-regulation/Editor";
import SidebarActions from "../../components/add-new-regulation/SidebarActions";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import * as smallToast from "react-hot-toast";
import {
  useCreateRegulationMutation,
  useGetSingleRegulationLazyQuery, useUpdateRegulationMutation
} from "../../graphql/generated/ncikenya-schema";

const AddNewRegulation = ({id, ...props}) => {

  const params = useParams();
  const [regulation, setRegulation] = useState();

  const [createRegulation] = useCreateRegulationMutation({
    onCompleted: (results) => {
      setRegulation((current) => {
        return {
          ...current,
          id: results.createRegulation.id
        }
      })
    }
  })

  const [getSingleRegulation] = useGetSingleRegulationLazyQuery({
    variables: {
      id: params?.id??id
    },
    onCompleted: (results) => {
      setRegulation(results.regulation)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateRegulation] = useUpdateRegulationMutation({
    onCompleted: (results) => {
      getSingleRegulation()
    }
  });

  const publishRegulation = (isDraft = true) => {
    if (regulation?.id) {
      smallToast.toast.promise(
        updateRegulation(
          {
            variables: {
              id: parseInt(regulation?.id),
              title: regulation?.title,
              file: regulation?.file,
            }
          }
        ),
        {
          success:"Regulation updated successfully",
          error:"Couldn't update regulation",
          loading:"Updating your regulation"
        }
      )

    } else
      smallToast.toast.promise(
        createRegulation(
          {
            variables: {
              title: regulation?.title,
              files: regulation?.files.map(regulationFile=>regulationFile.file),
              otherMedias: regulation?.otherImages?.map(i => i.file) ?? [],
              enabled: !isDraft
            }
          }
        ),
        {
          success:"Regulation created successfully",
          error:"Couldn't create regulation",
          loading:"Creating your regulation"
        }
      )

  }

  useEffect(() => {
    if (params?.id || id != null || id != undefined) {
      getSingleRegulation()
    }
  }, [])


  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={regulation?.id ? "Update Regulation" : "Add Regulation"}
                   subtitle="Regulations"
                   className="text-sm-left"/>
      </Row>

      <Row>
        {/* Editor */}
        <Col md="12">
          <Editor regulation={regulation} setRegulation={setRegulation} isFile={true} noContent/>
        </Col>

        {/* Sidebar Widgets */}
        <Col md="12">
          <SidebarActions selectedRegulation={regulation}
                          publishRegulation={publishRegulation}/>
          {/*<SidebarCategories/>*/}
        </Col>
      </Row>
    </Container>
  )
};

export default AddNewRegulation;
