import React, {Fragment, useState} from "react";
import {Container, Row, Col} from "shards-react";
import logo from "../images/logo.png"
import {useLoginMutation} from "../graphql/generated/ncikenya-schema";
import * as smallToast from "react-hot-toast";
import {Toaster} from "react-hot-toast";
import 'react-toastify/dist/ReactToastify.min.css';
import {ToastContainer} from "react-toastify";

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [loginInput, setLoginInput] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState();

  const [login] = useLoginMutation({
    variables: loginInput,
    onCompleted: (results) => {
      setLoading(false)
      window.location.replace("/");
    },
    onError: () => {
      setLoading(false)
    }
  })

  return (
    <Fragment>
      <Container fluid className="main-content-container px-4 h-100">
        <Row noGutters className="page-header py-4 h-100">
          <div className="auth-form mx-auto my-auto col-md-5 col-lg-3">
            <div className="card">
              <div className="card-body">
                <img className="auth-form__logo d-table mx-auto mb-3" width={200} src={logo}
                     alt="The NCI Kenya Dashboard Login"/>
                <h5 className="auth-form__title text-center mb-4">Access Your
                  Account</h5>
                <form onSubmit={(e) => {
                  e.preventDefault()
                  if (!loginInput?.email?.isValidEmail())
                    setErrors((current) => {
                      return {
                        ...current,
                        email: "A Valid email address is required"
                      }
                    })
                  if (!loginInput?.password?.isValidPassword())
                    setErrors((current) => {
                      return {
                        ...current,
                        password: "A Valid password address is required."
                      }
                    })
                  if (loginInput?.password?.isValidPassword() && loginInput?.email?.isValidEmail()) {
                    setErrors(null)
                    setLoading(true)
                    smallToast.toast.promise(
                      login(),
                      {
                        success:"Login Successful",
                        error:"Couldn't log you in",
                        loading:"Signing you in ..."
                      }
                    )
                  }
                }}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email
                      address</label>
                    <input type="email"
                           id="exampleInputEmail1"
                           placeholder="Enter email"
                           autoComplete="email"
                           className={`form-control ${errors?.email ? 'is-invalid' : ''}`}
                           onChange={(e) => {
                             e.persist();
                             setLoginInput((current) => {
                               return {
                                 ...current,
                                 email: e?.target?.value
                               }
                             })
                           }}
                    />
                    {errors?.email ? <div
                      className="invalid-feedback">{errors.email}</div> : null}

                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      autoComplete="current-password"
                      className={`form-control ${errors?.password ? 'is-invalid' : ''}`}
                      onChange={(e) => {
                        e.persist();
                        setLoginInput((current) => {
                          return {
                            ...current,
                            password: e?.target?.value
                          }
                        })
                      }}
                    />
                    {errors?.password ?
                      <div className="invalid-feedback">{errors.password}<p> A
                        minimum of 8 character with at least one number and one
                        special character</p></div> : null}
                  </div>
                  <div className="form-group">
                    <label
                      className="custom-control custom-checkbox">
                      <input
                        id="dr-checkbox-djOTUK_pA" type="checkbox"
                        className="custom-control-input"/>
                      <label
                        id="dr-checkbox-djOTUK_pA"
                        className="custom-control-label"
                        aria-hidden="true">

                      </label>
                      <span
                        className="custom-control-description">Remember me for 30 days.</span>
                    </label>
                  </div>
                  <button type="submit"
                          className="d-table mx-auto btn btn-accent btn-pill">Access
                    Account
                  </button>
                </form>
              </div>
              <div className="card-footer">
              </div>
            </div>
            <div className="auth-form__meta d-flex mt-4">
              <a href="/forgot-password">Forgot your password?</a>
            </div>
          </div>
        </Row>
      </Container>
      <Toaster/>
      <ToastContainer/>
    </Fragment>
  )
};

export default Login;
