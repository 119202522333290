import React, {useState} from "react";
import ReactQuill from "react-quill";
import {Badge, Card, CardBody, Col, Form, FormInput} from "shards-react";
import {Dropzone, FileMosaic} from "@dropzone-ui/react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import {useCreateBlogMutation} from "../../graphql/generated/ncikenya-schema";

const Editor = ({jobPost, setJobPost, isFile, noContent}) => {

  const updateFiles = (incomingFiles) => {
    setJobPost((current)=>{
      return {
        ...current,
        files: incomingFiles
      }

    })
  };


  let jobPostFiles = jobPost?.files??[];
  return (
    <Card small className="mb-3">
      <CardBody>
        <Form className="add-new-post">
          <FormInput size="lg" className="mb-3" placeholder={jobPost?.title??'Enter Job title here'}
                     onChange={(e) => {
                       const { name, value } = e.target;
                       setJobPost((current) => {
                         return {
                           ...current,
                           title: value,
                         }
                       })
                     }}/>
          <FormInput size="lg" className="mb-3" placeholder={jobPost?.position??'Enter Job Position here'}
                     onChange={(e) => {
                       const { name, value } = e.target;
                       setJobPost((current) => {
                         return {
                           ...current,
                           position: value,
                         }
                       })
                     }}/>
          <FormInput size="lg" className="mb-3" placeholder={jobPost?.location??'Enter Job Location here'}
                     onChange={(e) => {
                       const { name, value } = e.target;
                       setJobPost((current) => {
                         return {
                           ...current,
                           location: value,
                         }
                       })
                     }}/>
          <Dropzone
            onChange={updateFiles}
            value={jobPostFiles}
            minHeight={100}
            maxFileSize={isFile?20000000:5000000}
            acccept={"application/pdf"}
            label={"Add PDF Job Description"}
          >
            {jobPostFiles.map((file, index) => (
              <FileMosaic key={`mosaic_${index}`} {...file} preview/>
            ))}
          </Dropzone>

        </Form>
      </CardBody>
    </Card>
  )
};

export default Editor;

Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]
