import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";
import {
  useCreateBlogMutation,
  useGetSingleBlogLazyQuery, useUpdateBlogMutation
} from "../graphql/generated/ncikenya-schema";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import * as smallToast from "react-hot-toast";

const AddNewPost = ({props}) => {

  const params = useParams();
  const [blog, setBlog] = useState();

  const [createBlog] = useCreateBlogMutation({
    onCompleted: (results) => {
      setBlog((current) => {
        return {
          ...current,
          id: results.createBlog.id
        }
      })
    }
  })

  const [getSingleBlog] = useGetSingleBlogLazyQuery({
    variables: {
      id: params?.id
    },
    onCompleted: (results) => {
      setBlog(results.blog)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateBlog] = useUpdateBlogMutation({
    onCompleted: (results) => {
      getSingleBlog()
    }
  });

  const publishBlog = (isDraft = true) => {
    if (blog?.id) {
      smallToast.toast.promise(
        updateBlog(
          {
            variables: {
              id: parseInt(blog?.id),
              title: blog?.title,
              content: blog?.content,
            }
          }
        ),
        {
          success:"Blog updated successfully",
          error:"Couldn't update blog",
          loading:"Updating your blog"
        }
      )

    } else
      smallToast.toast.promise(
        createBlog(
          {
            variables: {
              title: blog?.title,
              content: blog?.content,
              coverImage: blog?.coverImage.file,
              otherImages: blog?.otherImages?.map(i => i.file) ?? [],
              enabled: !isDraft
            }
          }
        ),
        {
          success:"Blog created successfully",
          error:"Couldn't create blog",
          loading:"Creating your blog"
        }
      )

  }

  useEffect(() => {
    if (params?.id) {
      getSingleBlog()
    }
  }, [])


  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={blog?.id ? "Update Post" : "Add Post"}
                   subtitle="Blog Posts"
                   className="text-sm-left"/>
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <Editor blog={blog} setBlog={setBlog}/>
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <SidebarActions selectedBlog={blog}
                          publishBlog={publishBlog}/>
          <SidebarCategories/>
        </Col>
      </Row>
    </Container>
  )
};

export default AddNewPost;
