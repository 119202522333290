import React, {useState} from "react";
import ReactQuill from "react-quill";
import {Badge, Card, CardBody, Col, Form, FormInput} from "shards-react";
import {Dropzone, FileMosaic} from "@dropzone-ui/react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import {useCreateBlogMutation} from "../../graphql/generated/ncikenya-schema";

const Editor = ({blog, setBlog}) => {

  const updateFiles = (incomingFiles) => {
    setBlog((current)=>{

      return {
        ...current,
        coverImage: incomingFiles[0],
        otherImages: incomingFiles
      }

    })
  };


  let blogImages = blog?.coverImage?[blog?.coverImage, ...(blog?.otherImages ?? [])]:[];
  return (
    <Card small className="mb-3">
      <CardBody>
        <Form className="add-new-post">
          <FormInput size="lg" className="mb-3" placeholder="Your Post Title"
                     value={blog?.title??''}
                     onChange={(e) => setBlog((current) => {
                       return {
                         ...current,
                         title: e.target.value
                       }
                     })}/>
          <Dropzone
            onChange={updateFiles}
            value={blogImages}
            minHeight={100}
            maxFileSize={5000000}
            acccept={"image/*"}
            label={"Add Images to your Blog Post"}
          >
            {blogImages.map((file, index) => (
              <FileMosaic key={`mosaic_${index}`} {...file} preview/>
            ))}
          </Dropzone>
          <ReactQuill className="add-new-post__editor mb-1 mt-4"
                      value={blog?.content??''}
                      onChange={(value) => setBlog((current) => {
                        return {
                          ...current,
                          content: value
                        }
                      })}
                      formats={Editor.formats}/>
        </Form>
      </CardBody>
    </Card>
  )
};

export default Editor;

Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]
