import {EventEmitter} from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";

let _store = {
  menuVisible: false,
  navItems: [
    {
      title: "Dashboard",
      to: "/",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: "",
    },
    {
      title: "Carousels",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "#",
      subMenu:[
        {
          title: "Carousels List",
          htmlBefore: '<i class="material-icons">vertical_split</i>',
          to: "/carousel-sliders",
        },
        {
          title: "Add Carousel",
          htmlBefore: '<i class="material-icons">note_add</i>',
          to: "/add-carousel",
        },
      ]
    },
    {
      title: "Events",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "#",
      subMenu:[
        {
          title: "Events List",
          htmlBefore: '<i class="material-icons">vertical_split</i>',
          to: "/events",
        },
        {
          title: "Add New Event",
          htmlBefore: '<i class="material-icons">note_add</i>',
          to: "/add-new-event",
        },
      ]
    },
    {
      title: "Blog Posts",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "#",
      subMenu:[
        {
          title: "Blog Posts List",
          htmlBefore: '<i class="material-icons">vertical_split</i>',
          to: "/blog-posts",
        },
        {
          title: "Add New Post",
          htmlBefore: '<i class="material-icons">note_add</i>',
          to: "/add-new-post",
        }
      ]
    },
    {
      title: "Tenders",
      htmlBefore: '<i class="fa fa-briefcase"></i>',
      to: "/tenders",
      // subMenu:[
      //   {
      //     title: "Tender List",
      //     htmlBefore: '<i class="material-icons">vertical_split</i>',
      //     to: "/tenders",
      //   },
      //   {
      //     title: "Add New Tender",
      //     htmlBefore: '<i class="material-icons">note_add</i>',
      //     to: "/add-new-tender",
      //   }
      // ]
    },
    {
      title: "Regulations",
      htmlBefore: '<i class="fa fa-book"></i>',
      to: "/regulations",
      // subMenu:[
      //   {
      //     title: "Tender List",
      //     htmlBefore: '<i class="material-icons">vertical_split</i>',
      //     to: "/tenders",
      //   },
      //   {
      //     title: "Add New Tender",
      //     htmlBefore: '<i class="material-icons">note_add</i>',
      //     to: "/add-new-tender",
      //   }
      // ]
    },
    {
      title: "Job Posts",
      htmlBefore: '<i class="fa fa-briefcase"></i>',
      to: "/jobs",
      // subMenu:[
      //   {
      //     title: "Tender List",
      //     htmlBefore: '<i class="material-icons">vertical_split</i>',
      //     to: "/tenders",
      //   },
      //   {
      //     title: "Add New Tender",
      //     htmlBefore: '<i class="material-icons">note_add</i>',
      //     to: "/add-new-tender",
      //   }
      // ]
    },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ]
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({actionType, payload}) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
