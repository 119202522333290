import {useMemo} from "react";
import {ApolloClient, ApolloError, ApolloLink, InMemoryCache} from "@apollo/client";
//@ts-ignore
import {createUploadLink} from "apollo-upload-client";

const ncikenyaApiLink = createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_ENDPOINT || ""}/graphql`,
    credentials: "include",
});
export const apolloClient = new ApolloClient({
    link: ncikenyaApiLink,
    cache: new InMemoryCache({
        resultCaching: false,
        addTypename: false,
    }),
});
export const useApolloClient = () => {
    const ncikenyaClient = useMemo(
        () =>
            apolloClient,
        []
    );
    return ncikenyaClient;
};
