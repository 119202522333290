import React, {useState} from "react";
import PropTypes from "prop-types";
import {NavLink as RouteNavLink, useLocation} from "react-router-dom";
import {Collapse, DropdownItem, NavItem, NavLink} from "shards-react";

const SidebarNavItem = ({item}) => {

  const {hash, pathname} = useLocation();

  const [isMenuOpen, setMenuOpen] = useState((item?.subMenu?.map(s=>s.to)??[]).includes(pathname))
  return (
    <>
      <NavItem>
        <NavLink tag={RouteNavLink} to={item.to}
                 className={`dropdown ${item?.subMenu?.length>0 ? 'dropdown-toggle' : ''}`}
                 onClick={()=>setMenuOpen(!isMenuOpen)}>
          {item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{__html: item.htmlBefore}}
            />
          )}
          {item.title && <span>{item.title}</span>}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{__html: item.htmlAfter}}
            />
          )}

        </NavLink>
        {item?.subMenu?.length>0 && <Collapse
          navbar={true}
          open={isMenuOpen}
          className="dropdown-menu dropdown-menu-small"
        >
          {item?.subMenu?.map((subMenu, index) => {
            return <a key={`submenu_${index}`} className={`dropdown-item ${subMenu.to==pathname?'active':''}`}
                      tabIndex="0"
                      href={`${subMenu.to}`}>{subMenu.title}</a>
          })}

        </Collapse>}
      </NavItem>

    </>
  )
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
