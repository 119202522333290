import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import Editor from "../../components/add-new-tender/Editor";
import SidebarActions from "../../components/add-new-tender/SidebarActions";
import SidebarCategories from "../../components/add-new-tender/SidebarCategories";
import {
  useCreateBlogMutation,
  useGetSingleBlogLazyQuery, useUpdateBlogMutation
} from "../../graphql/generated/ncikenya-schema";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import * as smallToast from "react-hot-toast";
import {
  useCreateTenderMutation,
  useGetSingleTenderLazyQuery, useUpdateTenderMutation
} from "../../graphql/generated/ncikenya-schema";

const AddNewTender = ({id, ...props}) => {

  const params = useParams();
  const [tender, setTender] = useState();

  const [createTender] = useCreateTenderMutation({
    onCompleted: (results) => {
      setTender((current) => {
        return {
          ...current,
          id: results.createTender.id
        }
      })
    }
  })

  const [getSingleTender] = useGetSingleTenderLazyQuery({
    variables: {
      id: params?.id??id
    },
    onCompleted: (results) => {
      setTender(results.tender)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateTender] = useUpdateTenderMutation({
    onCompleted: (results) => {
      getSingleTender()
    }
  });

  const publishTender = (isDraft = true) => {
    if (tender?.id) {
      smallToast.toast.promise(
        updateTender(
          {
            variables: {
              id: parseInt(tender?.id),
              title: tender?.title,
              file: tender?.file,
            }
          }
        ),
        {
          success:"Tender updated successfully",
          error:"Couldn't update tender",
          loading:"Updating your tender"
        }
      )

    } else
      smallToast.toast.promise(
        createTender(
          {
            variables: {
              title: tender?.title,
              files: tender?.files.map(tenderFile=>tenderFile.file),
              otherMedias: tender?.otherImages?.map(i => i.file) ?? [],
              enabled: !isDraft
            }
          }
        ),
        {
          success:"Tender created successfully",
          error:"Couldn't create tender",
          loading:"Creating your tender"
        }
      )

  }

  useEffect(() => {
    if (params?.id || id != null || id != undefined) {
      getSingleTender()
    }
  }, [])


  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={tender?.id ? "Update Tender" : "Add Tender"}
                   subtitle="Tenders"
                   className="text-sm-left"/>
      </Row>

      <Row>
        {/* Editor */}
        <Col md="12">
          <Editor tender={tender} setTender={setTender} isFile={true} noContent/>
        </Col>

        {/* Sidebar Widgets */}
        <Col md="12">
          <SidebarActions selectedTender={tender}
                          publishTender={publishTender}/>
          {/*<SidebarCategories/>*/}
        </Col>
      </Row>
    </Container>
  )
};

export default AddNewTender;
