import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-home-slider/Editor";
import SidebarActions from "../components/add-home-slider/SidebarActions";
import SidebarCategories from "../components/add-home-slider/SidebarCategories";
import {
  useCreateBlogMutation,
  useCreateCarouselMutation,
  useGetSingleBlogLazyQuery,
  useGetSingleCarouselLazyQuery,
  useUpdateBlogMutation, useUpdateCarouselMutation
} from "../graphql/generated/ncikenya-schema";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import * as smallToast from "react-hot-toast";

const AddCarousel = ({props}) => {

  const params = useParams();
  const [carousel, setCarousel] = useState();

  const [createCarousel] = useCreateCarouselMutation({
    onCompleted: (results) => {
      setCarousel((current) => {
        return {
          ...current,
          id: results.createCarousel.id
        }
      })
    }
  })

  const [getSingleCarousel] = useGetSingleCarouselLazyQuery({
    variables: {
      id: params?.id
    },
    onCompleted: (results) => {
      setCarousel(results.carousel)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateCarousel] = useUpdateCarouselMutation({
    onCompleted: (results) => {
      getSingleCarousel()
    }
  });

  const publishCarousel = (isDraft = true) => {

    if (carousel?.id) {

      smallToast.toast.promise(
        updateCarousel(
          {
            variables: {
              id: parseInt(carousel?.id),
              title: carousel?.title,
              description: carousel?.description,
              action: carousel?.action,
            }
          }
        ),
        {
          success:"Carousel updated successfully",
          error:"Couldn't update carousel",
          loading:"Updating your carousel"
        }
      )

    } else

      smallToast.toast.promise(
        createCarousel(
          {
            variables: {
              title: carousel?.title,
              description: carousel?.description,
              action: carousel?.action,
              carouselImage: carousel?.carouselImage.file,
              enabled: !isDraft
            }
          }
        ),
        {
          success:"Carousel created successfully",
          error:"Couldn't create carousel",
          loading:"Creating your carousel"
        }
      )

  }

  useEffect(() => {
    if (params?.id) {
      getSingleCarousel()
    }
  }, [])


  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={carousel?.id ? "Update Carousel" : "Add Carousel"}
                   subtitle="Carousels"
                   className="text-sm-left"/>
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <Editor carousel={carousel} setCarousel={setCarousel}/>
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <SidebarActions selectedCarousel={carousel}
                          publishCarousel={publishCarousel}/>
        </Col>
      </Row>
    </Container>
  )
};

export default AddCarousel;
