import React, {useState} from "react";
import ReactQuill from "react-quill";
import {Badge, Card, CardBody, Col, Form, FormInput, Row} from "shards-react";
import {Dropzone, FileMosaic} from "@dropzone-ui/react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import Picker, {RangePicker} from "rc-picker";
import 'rc-picker/assets/index.css';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import enUS from 'rc-picker/lib/locale/en_US';
import * as moment from 'moment';

const Editor = ({event, setEvent}) => {

  const updateFiles = (incomingFiles) => {
    setEvent((current)=>{

      return {
        ...current,
        coverImage: incomingFiles[0],
        otherImages: incomingFiles
      }

    })
  };




  //   speakers:event?.speakers,
  //   program:event?.program,


  let eventImages = event?.coverImage?[event?.coverImage, ...(event?.otherImages ?? [])]:[];
  return (
    <Card small className="mb-3">
      <CardBody>
        <Form className="add-new-post">
          <FormInput size="lg" className="mb-3" placeholder="Your Post Title"
                     value={event?.title??''}
                     onChange={(e) => setEvent((current) => {
                       return {
                         ...current,
                         title: e.target.value
                       }
                     })}/>
          <Row className={'mt-4'}>
            <Col>
              <FormInput size="lg" className="mb-3" placeholder="Venue"
                         value={event?.venue??''}
                         onChange={(e) => setEvent((current) => {
                           return {
                             ...current,
                             venue: e.target.value
                           }
                         })}/>
            </Col>
            <Col>
              <FormInput size="lg" className="mb-3" placeholder="Venue Link"
                         value={event?.venueLink??''}
                         onChange={(e) => setEvent((current) => {
                           return {
                             ...current,
                             venueLink: e.target.value
                           }
                         })}/>
            </Col>
          </Row>
          <div className={'mt-4 mb-4 d-flex flex-row justify-content-center w-100'}>
            <RangePicker
              generateConfig={momentGenerateConfig}
              showTime={true}
              use12Hours={true}
              locale={enUS}
              separator={""}
              value={[moment(event?.from), moment(event?.to)]}
              onChange={(momentDate, stringDates)=>{
                setEvent((current) => {
                  return {
                    ...current,
                    from: momentDate[0].toDate(),
                    to:  momentDate[1].toDate(),
                  }
                })
              }}
            />
          </div>
          <Dropzone
            onChange={updateFiles}
            value={eventImages}
            minHeight={100}
            maxFileSize={5000000}
            acccept={"image/*"}
            label={"Add Images to your Event"}
          >
            {eventImages.map((file, index) => (
              <FileMosaic key={`mosaic_${index}`} {...file} preview/>
            ))}
          </Dropzone>
          <ReactQuill className="add-new-post__editor mb-1 mt-4"
                      value={event?.content??''}
                      onChange={(value) => setEvent((current) => {
                        return {
                          ...current,
                          content: value
                        }
                      })}
                      formats={Editor.formats}/>
        </Form>
      </CardBody>
    </Card>
  )
};

export default Editor;

Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]
