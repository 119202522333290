import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  InputGroup,
  InputGroupAddon,
  FormCheckbox,
  FormInput
} from "shards-react";

const SidebarSpeakers = ({event, setEvent}) => {

  const [addedGuest, setAddedGuest] = useState();

  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Event Guests</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="px-3 pb-2">
            {event?.speakers?.map((speaker, index) => {
              return <span key={`speaker_${index}`} className="d-flex mb-2">
            <div className={'d-flex flex-column'}>
              <strong
                className="mr-1">{`${speaker?.firstName ?? 'Anonymous'}`}</strong>
              {speaker?.title}
            </div>
                <a className="ml-auto" href="#" onClick={() => {
                  event?.speakers.splice(index, 1)
                  setEvent((current) => {
                    return {
                      ...current,
                      speakers: event?.speakers
                    }
                  })
                }
                }>
              Remove
            </a>
          </span>
            })}
            {!(event?.speakers?.length > 0) && <div
              className={'d-flex flex-column justify-content-center align-items-center'}>
              <h6>No Guests Added</h6>
              <label>Enter guest name and title to add one</label>
            </div>}
          </ListGroupItem>

          <ListGroupItem className="d-flex px-3">
            <InputGroup className="ml-auto">
              <FormInput placeholder="Speaker Names"
                         value={addedGuest?.firstName}
                         onChange={(e) => {
                           setAddedGuest({
                             title: addedGuest?.title,
                             firstName: e.target.value
                           })
                         }}/>
              <FormInput placeholder="Speaker Title"
                         value={addedGuest?.title}
                         onChange={(e) => {
                           setAddedGuest({
                             title: e.target.value,
                             firstName: addedGuest?.firstName
                           })
                         }}/>
              <InputGroupAddon type="append">
                <Button theme="white" className="px-2"
                        onClick={(e) => {
                          setEvent((current) => {
                            if (!addedGuest?.firstName || !addedGuest?.title) {
                              return current;
                            }
                            return {
                              ...current,
                              speakers: [
                                ...(current?.speakers??[]),
                                {
                                  ...addedGuest
                                }
                              ]
                            }
                          })
                          setAddedGuest(null)
                        }}>
                  <i className="material-icons">add</i>Add
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  )
};
export default SidebarSpeakers;
