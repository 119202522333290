/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button, CardHeader
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import {
  BlogSortFields, SortDirection,
  useDeleteBlogMutation, useGetBlogsLazyQuery,
  useGetBlogsQuery, useUpdateBlogMutation
} from "../graphql/generated/ncikenya-schema";
import * as moment from 'moment'

const BlogPosts = () => {
  const [blogs, setBlogs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const [getBlogs] = useGetBlogsLazyQuery({
    variables:{
      paging:{
        offset:offset,
        limit:limit
      },
      sorting:[
        {
          field:BlogSortFields.Id,
          direction:SortDirection.Desc
        }
      ]
    },
    onCompleted: (results) => {
      setBlogs(results.blogs.nodes)
      setTotalCount(results.blogs.totalCount)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [deleteBlog] = useDeleteBlogMutation({
    onCompleted: (results) => {
      getBlogs()
    }
  });
  const [updateBlog] = useUpdateBlogMutation({
    onCompleted: (results) => {
      getBlogs()
    }
  });

  const columns = [
    {
      name: 'Cover Image',
      selector: row => row.title,
      cell: (row) => {
        return <div className={'card-post__author d-flex m-2'}>
          <div className="card-post__author-avatar card-post__author-avatar"
               style={{backgroundImage: `url('${process.env.REACT_APP_BACKEND_ENDPOINT}/${encodeURI(row.coverImage.thumbnails[0])}')`}}
          />
        </div>
      }
    },
    {
      name: 'Title',
      selector: row => row.title,

    },
    {
      name: 'Content',
      selector: row => row.content,
      cell: (row) => {
        return <div>{row.content.replace(/(<([^>]+)>)/gi, "").ellipsizeMiddle(10)}</div>
      }
    },
    {
      name: 'Status',
      selector: row => row.content,
      cell: (row) => {
        return <div>{row.enabled ? 'Published' : 'Draft'}</div>
      }
    },
    {
      name: 'Author',
      selector: row => row.content,
      cell: (row) => {
        return <div>{`${row?.createdBy?.firstName ?? ''} ${row?.createdBy?.lastName ?? ''}`.trim() ?? 'Anonymous'}</div>
      }
    },
    {
      name: 'Created At',
      selector: row => row.content,
      cell: (row) => {
        return <div>{moment(row.createdAt).format('ddd, Do MMM YYYY')}</div>
      },
    },
    {
      name: 'Actions',
      selector: row => row.content,
      cell: (row) => {
        return <div className={'d-flex flex-row'}>
          <button className="btn btn-outline-primary btn-sm p-1 m-1"
                  onClick={() => updateBlog({
                    variables: {
                      id: parseInt(row?.id ?? '-1'),
                      enabled: !row.enabled
                    }
                  })}>
            <i
              className="material-icons font-weight-bold">delete</i>{row.enabled ? 'Deactivate' : 'Publish'}
          </button>
          <button className="btn btn-outline-primary btn-sm p-1 m-1"
                  onClick={() => {
                    window.location.replace(`/update-post/${row.id}`)
                  }
                  }>
            <i className="material-icons font-weight-bold">delete</i> Update
          </button>
          <button className="btn btn-outline-primary btn-sm p-1 m-1"
                  onClick={() => deleteBlog({variables: {id: parseInt(row?.id ?? '-1')}})}>
            <i className="material-icons font-weight-bold">delete</i> Delete
          </button>
        </div>
      },
      minWidth: "300px",
    },
  ];


  useEffect(() => {
    getBlogs()
  }, [offset, limit])

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Blog Posts" subtitle="Components"
                   className="text-sm-left"/>
      </Row>
      <Col>
        <Card small className="card-post mt-4">
          <CardHeader className="border-top d-flex">
            <div className="card-post__author d-flex">
              <div className="d-flex flex-column justify-content-center ml-3">
                <h5 className="card-post__author-name">
                  NCI Kenya Blog Posts
                </h5>
                <small
                  className="text-muted">{'Posts Viewed on the NCI Kenya Website'}</small>
              </div>
            </div>
            <div className="my-auto ml-auto">
              <Button size="sm" theme="white">
                <i className="far fa-bookmark mr-1"/> Bookmark
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <DataTable
              columns={columns}
              data={blogs}
              selectableRows
              pagination={true}
              paginationPerPage={limit}
              paginationServer={true}
              paginationTotalRows={totalCount}
              paginationRowsPerPageOptions={[1,10, 25, 50, 100]}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage)=>{
                setLimit(currentRowsPerPage)
              }}
              onChangePage={(page, totalRows)=>{
                setOffset((page-1)*limit)
              }}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default BlogPosts;
