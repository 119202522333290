/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button, CardHeader, Modal, ModalFooter, ModalBody
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import {
   SortDirection,
  useDeleteRegulationMutation, useGetRegulationsLazyQuery,
   useUpdateRegulationMutation
} from "../../graphql/generated/ncikenya-schema";
import * as moment from 'moment'
import {RegulationSortFields} from "../../graphql/generated/ncikenya-schema";
import {useCookies} from "react-cookie";
import AddNewRegulation from "./AddNewRegulation";

const Regulations = () => {
  const [regulations, setRegulations] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [updateRegulationId, setUpdateRegulationId] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [cookie, setCookie, removeCookie] = useCookies();
  const [createRegulation, setCreateRegulation] = useState(false);

  const [getRegulations] = useGetRegulationsLazyQuery({
    variables:{
      paging:{
        offset:offset,
        limit:limit
      },
      sorting:[
        {
          field:RegulationSortFields.Id,
          direction:SortDirection.Desc
        }
      ]
    },
    onCompleted: (results) => {
      setRegulations(results.regulations.nodes)
      setTotalCount(results.regulations.totalCount)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [deleteRegulation] = useDeleteRegulationMutation({
    onCompleted: (results) => {
      getRegulations()
    },
    onError:(error)=>{
      if(error?.message == 'GqlAuthGuard'){
        removeCookie('user');
        removeCookie('owner');
        removeCookie('token');
        window.location.replace("/login")
      }
  }
  });
  const [updateRegulation] = useUpdateRegulationMutation({
    onCompleted: (results) => {
      getRegulations()
    }
  });

  const columns = [
    {
      name: 'Title',
      selector: row => row.title,

    },
    {
      name: 'Status',
      selector: row => row.content,
      cell: (row) => {
        return <div>{row.enabled ? 'Published' : 'Draft'}</div>
      },
      width:"100px"
    },
    {
      name: 'Author',
      selector: row => row.content,
      cell: (row) => {
        return <div>{`${row?.createdBy?.firstName ?? ''} ${row?.createdBy?.lastName ?? ''}`.trim() ?? 'Anonymous'}</div>
      },
      maxWidth:"150px"
    },
    {
      name: 'Created At',
      selector: row => row.content,
      cell: (row) => {
        return <div>{moment(row.createdAt).format('ddd, Do MMM YYYY')}</div>
      },
    },
    {
      name: 'Actions',
      selector: row => row.content,
      cell: (row) => {
        return <div className={'d-flex flex-row'}>
          <button className="btn btn-outline-primary btn-sm p-1 m-1">
            <i
              className="fa fa-download font-weight-bold"></i>
            <a href={`${process.env.REACT_APP_BACKEND_ENDPOINT}/${encodeURI(row.mainFile.addresses[0])}`}>{' Download'}</a>
          </button>
          <button className="btn btn-outline-primary btn-sm p-1 m-1"
                  onClick={() => updateRegulation({
                    variables: {
                      id: parseInt(row?.id ?? '-1'),
                      enabled: !row.enabled
                    }
                  })}>
            <i
              className="fa fa-upload font-weight-bold"></i>{row.enabled ? ' Deactivate' : ' Publish'}
          </button>
          <button className="btn btn-outline-primary btn-sm p-1 m-1"
                  onClick={() => {
                    setUpdateRegulationId(parseInt(row?.id ?? '-1'))
                    setCreateRegulation(true)
                    // window.location.replace(`/update-post/${row.id}`)
                  }
                  }>
            <i className="fa fa-sync font-weight-bold"></i> Update
          </button>
          <button className="btn btn-outline-primary btn-sm p-1 m-1"
                  onClick={() => deleteRegulation({variables: {id: parseInt(row?.id ?? '-1')}})}>
            <i className="material-icons font-weight-bold">delete</i> Delete
          </button>
        </div>
      },
      minWidth: "350px",
    },
  ];


  useEffect(() => {
    getRegulations()
  }, [offset, limit])

  return (
    <>

      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Regulations" subtitle="Components"
                     className="text-sm-left"/>
        </Row>
        <Col>
          <Card small className="card-post mt-4">
            <CardHeader className="border-top d-flex">
              <div className="card-post__author d-flex">
                <div className="d-flex flex-column justify-content-center ml-3">
                  <h5 className="card-post__author-name">
                    NCI Kenya Regulations
                  </h5>
                  <small
                    className="text-muted">{'Posts Viewed on the NCI Kenya Website'}</small>
                </div>
              </div>
              <div className="my-auto ml-auto">
                <Button size="sm" theme="primary" onClick={()=>{
                  setUpdateRegulationId(undefined)
                  setCreateRegulation(true)
                }}>
                  <i className="fa fa-plus mr-1"/> Create Regulation
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <DataTable
                columns={columns}
                data={regulations}
                selectableRows
                pagination={true}
                paginationPerPage={limit}
                paginationServer={true}
                paginationTotalRows={totalCount}
                paginationRowsPerPageOptions={[1,10, 25, 50, 100]}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage)=>{
                  setLimit(currentRowsPerPage)
                }}
                onChangePage={(page, totalRows)=>{
                  setOffset((page-1)*limit)
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Container>
      <Modal
        open={createRegulation}
        size={'lg'}
        position={'centered'}
        // onAfterOpen={afterOpenModal}
        toggle={()=>setCreateRegulation(!createRegulation)}
        // style={customStyles}
        contentLabel="Create Regulation"
      >
        <ModalBody>
          <AddNewRegulation id={updateRegulationId}/>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Regulations;
