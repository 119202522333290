import React, {useState} from "react";
import ReactQuill from "react-quill";
import {Badge, Card, CardBody, Col, Form, FormInput, Row} from "shards-react";
import {Dropzone, FileMosaic} from "@dropzone-ui/react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import {useCreateBlogMutation} from "../../graphql/generated/ncikenya-schema";

const Editor = ({carousel, setCarousel}) => {

  const updateFiles = (incomingFiles) => {
    setCarousel((current)=>{
      return {
        ...current,
        carouselImage: incomingFiles[0]
      }

    })
  };


  let carouselImages = carousel?.carouselImage?[carousel?.carouselImage]:[];
  return (
    <Card small className="mb-3">
      <CardBody>
        <Form className="add-new-post">
          <FormInput size="lg" className="mb-3" placeholder="Carousel Title"
                     value={carousel?.title??''}
                     onChange={(e) => setCarousel((current) => {
                       return {
                         ...current,
                         title: e.target.value
                       }
                     })}/>
          <Row>
            <Col>
              <FormInput size="lg" className="mb-3" placeholder="Description"
                         value={carousel?.description??''}
                         onChange={(e) => setCarousel((current) => {
                           return {
                             ...current,
                             description: e.target.value
                           }
                         })}/>
            </Col>
            <Col>
              <FormInput size="lg" className="mb-3" placeholder="Action"
                         value={carousel?.action??''}
                         onChange={(e) => setCarousel((current) => {
                           return {
                             ...current,
                             action: e.target.value
                           }
                         })}/>
            </Col>

          </Row>
          <Dropzone
            onChange={updateFiles}
            value={carouselImages}
            minHeight={100}
            maxFileSize={5000000}
            acccept={"image/*"}
            label={"Add Images to your Blog Post"}
          >
            {carouselImages?.map((file, index) => (
              <FileMosaic key={`mosaic_${index}`} {...file} preview/>
            ))}
          </Dropzone>
        </Form>
      </CardBody>
    </Card>
  )
};

export default Editor;

Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]
